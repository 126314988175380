#background {
  height: 100vh;
  background-image: url('../../images/bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  #background {
    background-image: url('../../images/bgpad.svg');
  }
}

@media only screen and (max-width: 374px) {
  #background {
    background-image: url('../../images/bgmobile.svg');
  }
}
