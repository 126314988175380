.formdiv {
  padding: 1rem;
}

input {
  width: 75%;
  padding: 0.5rem;
  margin-bottom: 0.2rem;
  border-radius: 1px;
}

input:focus {
  outline: none;
}

#grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carddiv {
  margin: 1rem;
}
